import React, { useState,useEffect, useCallback } from 'react'
import {Link, useParams} from 'react-router-dom'
import trip_home_icon from '../../../assets/image/web-icons/home.png'
import { useDispatch, useSelector } from 'react-redux'
import './../../../css/main.css'
import { history } from './../../../functions/_helpers'
import Input from 'react-phone-number-input/input'
import {uploadService, userService} from '../../../functions/services'
import {useDropzone} from 'react-dropzone'
import jwt from 'jwt-decode'
import {Alert} from '../../alerts'


const UserEdit= () =>{
   const { id }= useParams();
   const [submitted, setSubmitted] = useState(false);
   const registering = useSelector(state => state.registration.registering);
   const dispatch = useDispatch();
   const [changes, setChanges] = useState(false);
   const [phoneValue, setPhoneValue] = useState();
   const [errors, setErrors] = useState();


   const [user, setUser] = useState({ 
      userId:'',    
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      userPassword: '',
      contactMethod: 0,
      role: 0,
      company: '',
      isAdmin: false,    /*It will be used if the user is løyvehavere or sjåfører*/
      profileImageUrl: '',
      address: '',
      active:true,
      deleted:false,
      receiveOperationStatus:false
   });

   /* Check access rights. Only admin (1) is allowed */
   let user_data = JSON.parse(localStorage.getItem('user'))==null ?  [] : JSON.parse(localStorage.getItem('user'));
   if(user_data === null || user_data.length === 0)
   {
      localStorage.clear();
      history.push('/login');
   }

   // Check Role
   const decodedToken = jwt(user_data.token);
   if (decodedToken == null || decodedToken == undefined) {
         localStorage.clear();
         history.push('/login');  
   }

   const role = parseInt(decodedToken.role);
   if(role !== 1){
      history.push('/');
   }

   useEffect(() => {
      
      if (id > 0) {
         userService.getById(id)
         .then(
            (response) => {
               setUser(response);
               setPhoneValue(response.phoneNumber);
            },
            (error) => {
               console.log(error);
            }
         )
      }       
   }, []);

   function handleError(error){
      try {
         error = JSON.parse(error);
      }
      catch(ex){
         console.log(ex);
      }
      const errorText = error && error.message? error.message: `Det oppstod en feil. ${typeof error === 'string'? error: ''}`;
      setErrors(errorText);
   }

   function handleChange(e) {
      const { name, value } = e.target;
      setUser(user => ({ ...user, [name]: value }));
      setChanges(true);
   }

   const handleFormCheckbox = (e, name) => {
      const checked = ((e || {}).target || {}).checked;
      setUser(user => ({ ...user, [name]: checked }));
      setChanges(true);
   }

   function handleSubmit(e) {
      e.preventDefault();
      setSubmitted(true);
      setUser(user => ({ ...user, role: parseInt(user.role)}));

      setErrors(null);

      if(changes || (user.phoneNumber !== phoneValue)){
         user.phoneNumber = phoneValue
         user.role = parseInt(user.role)
         if(user.role === 1){
            user.isAdmin=true;
         }

         if (id > 0) {
            userService.updateUser(user)
            .then(
               (response) => {
                  history.push('/users');
               },
               (error) => {

                  handleError(error);
               })
         }
         else {
            userService.register(user)
            .then(
               (response) => {
                  history.push('/users');
               },
               (error) => {

                  handleError(error);
               })
         }

      }
   }
    
   const gotoHome = () => {
         history.push('/')
   }
   
   const handleBack = () => {
      //history.goBack();
      history.push('/users');
   }

/* Callback for profile image */
   const onDrop = useCallback(acceptedFiles => {
      // Only allow one file
      if (acceptedFiles.length != 1) {
         return;
      }

      var fileType = acceptedFiles[0].name.split('.').pop().toLowerCase();

      // allow jpg, png and gif
      if (fileType != 'jpg' && fileType != 'jpeg' && fileType != 'png' && fileType != 'gif') {
         return;
      }

      setChanges(true);

      uploadService.upload(acceptedFiles[0])
      .then ((fileName) => {
         var url = process.env.REACT_APP_API_URL + '/' + fileName;

         setUser(user => ({ ...user, profileImageUrl: url}));
      })
      .catch((err) => console.error(err));

   }, []);

  const {getRootProps, getInputProps } = useDropzone({onDrop});

  const getProfileImage = function(imageUrl) {
      if (imageUrl !== null && imageUrl !== 'undefined' && imageUrl !== '') {
         return (
            <div className="profile-image" {...getRootProps()} title="Klikk for å laste opp nytt bilde">
               <input  {...getInputProps()} />
               <img src={imageUrl} />
            </div>
         )
      }
      else {
         return ( 
            <div className="upload-dialog-border" {...getRootProps()}>
               <input {...getInputProps()} />
               <div className="frame-header">Dra & slipp</div>
               <div className="frame-text">eller klikk for å laste opp</div>
            </div>);
      }
   }



   const getMenuText = function() {
      if (id == 0){
         return "LEGG TIL";
      }
      else {
         return "ENDRE";
      }
   }

   const getHeading = function() {
      if (id == 0){
         return "Ny bruker";
      }
      else {
         return "Endre bruker";
      }
   }

   const closeError = () => {
      setErrors(null);
   }

   return (
      <>
         <div className="w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4 top-adjustment custom-border-main">

            <div className="flex w-full trip_header ml-2">
               <div className="col-6 text-left">
                  <img src={trip_home_icon} alt="home" onClick={gotoHome}/>
                  <span className="font-bold"> <Link to='/' style={{textDecoration:'none'}}>HJEM</Link> &nbsp; &nbsp; / &nbsp; </span>
                  <span className="font-bold"> <Link to='/users'> BRUKERE </Link>&nbsp; &nbsp; / &nbsp; </span>
                  <span className="font-bold"> {getMenuText()} &nbsp; &nbsp; / &nbsp; </span>
               </div>
               <div className="text-right inline-flex" style={{position:"absolute",right:"30px"}}>
                  <button type="button" className="add_btn" id="backBtn" onClick={()=>handleBack()}> Tilbake </button>
               </div>
            </div>
                  
            <div className="w-full trip_box ml-2 custom-add-item-border" style={{overflowY:"scroll",height:"80vh",width:"98.8%"}}>
                  <form name="form" className="form flex flex-wrap w-full" onSubmit={handleSubmit}>
                     <h4 className="trip_title font-bold mb-3"> {getHeading()} </h4>

                     <>
                        {errors ? <div className="w-full mb-4">
                           <Alert
                           color="bg-transparent border-red-500 text-red-500 alert-error"
                           onClose={closeError}
                           borderLeft
                           raised>
                           {errors}
                           </Alert>
                        </div>: null}
                     </>

                     <div className="page-content w-full trips drivers">
                        <div className="flex-2 form-container-column">
                              <label className="font-bold block"> Profilbilde (Passfoto) </label>
                              <div className="profile-image-container">
                              {getProfileImage(user.profileImageUrl)}
                              </div>
                        </div>

                        <div className="flex-4 form-container-column">
                           <label className="font-bold block"> Fornavn </label>
                           <input type="text" style={{border:"1px solid #37708e"}} name="firstName" value={user.firstName} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} onChange={handleChange} className={'form-control' + (submitted && !user.firstName ? ' is-invalid' : '')} />
                           <label className="font-bold block"> Etternavn </label>
                           <input type="text" style={{border:"1px solid #37708e"}} name="lastName" value={user.lastName} onChange={handleChange} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} className={'form-control' + (submitted && !user.lastName ? ' is-invalid' : '')} />
                           <label className="font-bold block"> Epost </label>
                           <input type="text" style={{border:"1px solid #37708e"}} name="email" value={user.email} onChange={handleChange} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} className={'form-control' + (submitted && !user.email ? ' is-invalid' : '')} />
                           <label className="font-bold block"> Telefonnummer </label>
                           <Input className="form-control" style={{border:"1px solid #37708e"}}
                              name="phoneNumber"
                              value={phoneValue}
                              onChange={setPhoneValue} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}/>
                        </div>


                        <div className="flex-4 form-container-column">
                           <label className="font-bold block"> Adresse </label>
                           <input type="text" style={{border:"1px solid #37708e"}} name="address"  value={user.address} onChange={handleChange} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} className={'form-control' + (submitted && !user.address ? ' is-invalid' : '')} />
                           <label className="font-bold block"> Selskap </label>
                           <input type="text" style={{border:"1px solid #37708e"}} name="company"  value={user.company} onChange={handleChange} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} className={'form-control' + (submitted && !user.company ? ' is-invalid' : '')} />
                        
                           <div>
                              <label className="font-bold block">Motta alarmer på sms</label>
                              <input type="checkbox" 
                                 checked={user.receiveOperationStatus} 
                                 style={{width:'auto', marginLeft:'10px'}}
                                 onChange={(e)=>{ handleFormCheckbox(e, 'receiveOperationStatus')}}/> 
                             
                           </div>
                        
                        </div>

                        <div className="flex-4 form-container-column">
                           <label className="font-bold block"> Passord webportal</label>
                           <input type="password" style={{border:"1px solid #37708e"}} name="userPassword" value={user.userPassword} onChange={handleChange} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} className={'form-control' + (submitted && !user.userPassword ? ' is-invalid' : '')} />
                           <label className="font-bold block"> Velge Rolle </label>
                                        <select className={'form-control' + (submitted && !user.role ? ' is-invalid' : '')} name="role" value={user.role} onChange={handleChange}>
                                            <option value="0" disabled="true"> Ukjent rolle</option>
                                            <option value="1"> Admin </option>
                                            <option value="2"> Løyvehaver </option>
                                            <option value="3"> Sjåfør </option>
                                        </select>
                        </div>
                     </div>
                     <div className="driver_btns text-right mt-10" style={{float: "right",marginRight: "0px",height: "45px",margin: "30px auto"}}>
                        <button type="button" className="sign_btn" style={{marginLeft:"10px",marginRight:"10px"}} onClick={()=>handleBack()}> Avbryt </button>
                        <button type="button" className="sign_btn" onClick={(e)=>handleSubmit(e)}> Lagre {registering && <span className="spinner-border spinner-border-sm mr-1"></span>} </button>
                     </div>
                  </form>
            </div>
         </div>
      </>
    )
}
export default UserEdit
