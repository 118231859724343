import { authHeader, history } from '../_helpers';

export const myTripsService = {
  tripSearch,
  preOrderSearch,
  getTripLog,
  getReport,
  getPermitDetails,
  getTravellerDetails,
  getStops
}

function tripSearch(model) {
  const requestOptions = {
      method: 'POST',
      headers: {...authHeader(),'Content-Type': 'application/json' },
      body: JSON.stringify(model)
  };
  const TIMEOUT = 180000;

  const fetchWithTimeout = (url, options, timeout = TIMEOUT) => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchPromise = fetch(url, { ...options, signal });

    const timeoutPromise = new Promise((_, reject) => {
      setTimeout(() => {
        controller.abort();
        reject(new Error('Request timed out'));
      }, timeout);
    });

    return Promise.race([fetchPromise, timeoutPromise]);
  };
  return fetchWithTimeout(process.env.REACT_APP_API_URL + '/api/tripSearch', requestOptions,TIMEOUT).then(handleResponse, handleError);
}

function getTripLog(id) {
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };

  return fetch(process.env.REACT_APP_API_URL + '/api/tripSearch/' + id, requestOptions).then(handleResponse, handleError);
}

function preOrderSearch(model) {
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };

  let url = process.env.REACT_APP_API_URL + '/api/tripSearch/getPreorders?index=' + model.index + '&take=' + model.take;

  if (model.orderField) {
    url += `&orderField=${model.orderField}`;
  }

  if (typeof model.ascending != undefined) {
    url += `&ascending=${model.ascending}`;
  }

  return fetch(url, requestOptions).then(handleResponse, handleError);
}

function getPermitDetails(permitNumber) {
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };

    return fetch(process.env.REACT_APP_API_URL + '/api/tripSearch/permitDetails/' + permitNumber, requestOptions).then(handleResponse, handleError);
}

function getTravellerDetails(bookingNumber) {
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };

  return fetch(process.env.REACT_APP_API_URL + '/api/tripSearch/travellerDetails/' + bookingNumber, requestOptions).then(handleResponse, handleError);
}

function getStops(bookingNumber) {
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };

  return fetch(process.env.REACT_APP_API_URL + '/api/tripSearch/getStops/' + bookingNumber, requestOptions).then(handleResponse, handleError);
}



function handleResponse(response) {
  return new Promise((resolve, reject) => {
      if (response.ok) {
          // return json if it was returned in the response
          var contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/json")) {
              response.json().then(json => resolve(json));
          } else {
              resolve();
              response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'report.xlsx';
                a.click();
              })
          }
      } else {
          // return error message from response body
          response.text().then(text => reject(text));
          console.log(response);
          if(response.status === 401){
              localStorage.clear();
              history.push('/login');
          }
      }
  });
}

function getReport(model) {
  const abortController = new AbortController();
  const signal = abortController.signal;

  const timeoutId = setTimeout(() => abortController.abort(), 300000);

  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(model),
    signal: signal  
  };

  return fetch(process.env.REACT_APP_API_URL + '/api/tripSearch/getReport', requestOptions)
    .then(handleResponse)
    .catch(handleError)
    .finally(() => clearTimeout(timeoutId));  
}


function handleError(error) {
  return Promise.reject(error && error.message);
}