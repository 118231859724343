import React, { useState,useEffect, useCallback, useRef,  } from 'react'
import { useParams, Link } from "react-router-dom";
import trip_home_icon from '../../../assets/image/web-icons/home.png'
import './../../../css/main.css'
import { history } from './../../../functions/_helpers'
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import Input from 'react-phone-number-input/input'
import _ from "lodash";
import {uploadService} from '../../../functions/services'
import {useDropzone} from 'react-dropzone'
import { FaRegFile, FaRegFilePdf, FaRegFileImage, FaPlus  } from 'react-icons/fa';
import {driverService} from '../../../functions/services'
import jwt from 'jwt-decode'
import {Alert} from '../../alerts'
import { PROPERTY_TYPE } from '../../../functions/_constants/trip.constants';
import {DbIcon} from  '../../icons/dbIcons';

const DriverEdit = () =>{
   const { id }= useParams();

   const [submitted, setSubmitted] = useState(false);
   const [changes, setChanges] = useState(false);
   const [phoneValue, setPhoneValue] = useState();
   const [driver, setDriver] = useState({
      userId: 0,
      driverId:0,
      driverNumber: '',
      vatNumber: '',
      accountNumber: '',
      passportUrl: "",
      drivingLicenseNumber:'',
      pcvLicenseUrl: "",
      employmentContractUrl: "",
      drivingLicenseUrl: "",
      deleted: false,
      firstName: '',
      lastName:'',
      displayName:'',
      email: '',
      phoneNumber: '',
      passwordApp: '',
      passwordWeb: '',
      showPhotoInApp:false,
      homeLocations:[]
   });
   const [extraProperties, setExtraProperties] = useState([]);
   const [errors, setErrors] = useState(null);

   const uploadDrivingLicenseRef = React.createRef();
   const uploadPcvLicenseRef = React.createRef();
   const uploadEmploymentContractRef = React.createRef();

   /* Check access rights. Only admin (1) is allowed */
   let user_data = JSON.parse(localStorage.getItem('user'))==null ?  [] : JSON.parse(localStorage.getItem('user'));
   if(user_data === null || user_data.length === 0)
   {
      localStorage.clear();
      history.push('/login');
   }

   // Check Role
   const decodedToken = jwt(user_data.token);
   if (decodedToken == null || decodedToken == undefined) {
         localStorage.clear();
         history.push('/login');  
   }

   const role = parseInt(decodedToken.role);
   if(role !== 1){
      history.push('/');
   }

   useEffect(()=>{
      // Load driver
      if (id > 0) {
         driverService.getDriver(id)
         .then(
           (response) => {
              setDriver(response);
              setPhoneValue(response.phoneNumber);
             // setDisplayName(response);
           },
           (error) => {
              console.log(error);
           }
         )
      }

      /** get extra dynamic properties */
      const idArg = id || null;
      driverService.getExtraDriverProperties(idArg).then(response=>{
         setExtraProperties(response);
      }, (error)=> {
         console.log(error);
         handleError(error);
      });

   },[]);


   const setDisplayName = (driverData) => {
      if (driverData.displayName == null || driverData.displayName == '') {
         setDriver(driver => ({ ...driver, displayName: driverData.firstName + ' ' + driverData.lastName }));
      }
   }


   /* Callback for profile image */
   const onDrop = useCallback(acceptedFiles => {
      // Only allow one file
      if (acceptedFiles.length != 1) {
          return;
      }

      var fileType = acceptedFiles[0].name.split('.').pop().toLowerCase();

      // allow jpg, png and gif
      if (fileType != 'jpg' && fileType != 'jpeg' && fileType != 'png' && fileType != 'gif') {
         return;
      }

      setChanges(true);

      uploadService.upload(acceptedFiles[0])
      .then ((fileName) => {
         var url = process.env.REACT_APP_API_URL + '/' + fileName;

         setDriver(driver => ({ ...driver, passportUrl: url}));
      })
      .catch((err) => console.error(err));

   }, []);

   const {getRootProps, getInputProps } = useDropzone({onDrop});
 


   function handleChange(e) {
      const { name, value } = e.target;
      setDriver(driver => ({ ...driver, [name]: value }));
      setChanges(true);
   }

   /** updates dynamic properties, works only with boolean types. */
   function handleExtraPropertiesChange(propId, e) {
      const checked = ((e || {}).target || {}).checked;

      let value = !checked? "0": "1";

      const tempItemIndex = (extraProperties || []).findIndex(x=>x.appPropertyId === propId);
      let updatedExtraProperties = extraProperties? [...extraProperties]: [];

      if(tempItemIndex >= 0){
         let propertyTemp = {...updatedExtraProperties[tempItemIndex]};
         if (propertyTemp){
            propertyTemp.value = value;
            updatedExtraProperties[tempItemIndex] = propertyTemp;
         }
      }

      console.log('updated extra properties');
      console.log(updatedExtraProperties);

      setExtraProperties(updatedExtraProperties);
      setChanges(true);
   }

   function handleDisplayPictureChange() {
      var oldValue = driver.showPhotoInApp
      setDriver(driver => ({ ...driver, showPhotoInApp: !oldValue }));
      setChanges(true);
   }
   
   function handleError(error){
      console.log(typeof error);
      try {
         error = JSON.parse(error);
      }
      catch(ex){
         console.log(ex);
      }
      const errorText = error && error.message? error.message: `Det oppstod en feil. ${typeof error === 'string'? error: ''}`;
      setErrors(errorText);
   }
   
   function handleSubmit(e) {
      e.preventDefault();
      setSubmitted(true);
      setErrors(null);
      
      if(changes|| (driver.phoneNumber !== phoneValue)) {
         driver.phoneNumber = phoneValue
         driver.extraProperties = extraProperties;

         if (id > 0) {
            driverService.putDriver(driver)
            .then(
            (response) => {
               history.push('/drivers');
            },
            (error) => {
               console.log(error);
               handleError(error);
               
            })
         }
         else {
            driverService.postDriver(driver)
            .then(
            (response) => {
               history.push('/drivers');
            },
            (error) => {
               console.log(error);
               handleError(error);

            })
         }
      }
   }

   const handleBack = () => {
      history.push('/drivers');
   }




   const nullstillDriving = (e) =>{
      e.stopPropagation();
      setChanges(true);
      driver.drivingLicenseUrl=null;  
      setDriver(driver => ({ ...driver, drivingLicenseUrl: null}));
   }
   const nullstillEmpContract = (e) =>{
      e.stopPropagation();
      setChanges(true);
      driver.employmentContractUrl=null;  
      setDriver(driver => ({ ...driver, employmentContractUrl: null}));
   }
   const nullstillPcv = (e) =>{
      e.stopPropagation();
      setChanges(true);
      driver.pcvLicenseUrl=null;  
      setDriver(driver => ({ ...driver, pcvLicenseUrl: null}));
   }


   function dropHandlerDrivingLicense(ev) {
      ev.preventDefault();
      // Only allow one file
      if (!ev.dataTransfer.items || ev.dataTransfer.items.length != 1) {
         return;
      }

      var fileType = ev.dataTransfer.items[0].type;
      if (fileType != 'image/jpg' && fileType != 'image/jpeg' && fileType != 'image/png' && fileType != 'image/gif' && fileType != 'application/pdf') {
         return;
      }
    
      setChanges(true);
      if (ev.dataTransfer.items[0].kind === 'file') {
         var file = ev.dataTransfer.items[0].getAsFile();

         uploadService.upload(file)
         .then ((fileName) => {
            var url = process.env.REACT_APP_API_URL + '/' + fileName;
            setDriver(driver => ({ ...driver, drivingLicenseUrl: url}));
         })
         .catch((err) => console.error(err));
      }
   }

   function dropHandlerEmploymentContract(ev) {
      ev.preventDefault();
      // Only allow one file
      if (!ev.dataTransfer.items || ev.dataTransfer.items.length != 1) {
         return;
      }

      var fileType = ev.dataTransfer.items[0].type;
      if (fileType != 'image/jpg' && fileType != 'image/jpeg' && fileType != 'image/png' && fileType != 'image/gif' && fileType != 'application/pdf') {
         return;
      }
    
      setChanges(true);
      if (ev.dataTransfer.items[0].kind === 'file') {
         var file = ev.dataTransfer.items[0].getAsFile();

         uploadService.upload(file)
         .then ((fileName) => {
            var url = process.env.REACT_APP_API_URL + '/' + fileName;
            setDriver(driver => ({ ...driver, employmentContractUrl: url}));
         })
         .catch((err) => console.error(err));
      }
   }

   function dropHandlerPcvLicense(ev) {
      ev.preventDefault();
      // Only allow one file
      if (!ev.dataTransfer.items || ev.dataTransfer.items.length != 1) {
         return;
      }
      
      var fileType = ev.dataTransfer.items[0].type;
      if (fileType != 'image/jpg' && fileType != 'image/jpeg' && fileType != 'image/png' && fileType != 'image/gif' && fileType != 'application/pdf') {
         return;
      }
    
      setChanges(true);
      if (ev.dataTransfer.items[0].kind === 'file') {
         var file = ev.dataTransfer.items[0].getAsFile();

         uploadService.upload(file)
         .then ((fileName) => {
            var url = process.env.REACT_APP_API_URL + '/' + fileName;
            setDriver(driver => ({ ...driver, pcvLicenseUrl: url}));
         })
         .catch((err) => console.error(err));
      }
   }
   
   function uploadDrivingLicense(ev) {
      ev.preventDefault();
      ev.stopPropagation();

      // Only allow one file
      if (!ev.target.files || ev.target.files.length != 1) {
         return;
      }
      
      var fileType = ev.target.files[0].type;
      if (fileType != 'image/jpg' && fileType != 'image/jpeg' && fileType != 'image/png' && fileType != 'image/gif' && fileType != 'application/pdf') {
         return;
      }

      setChanges(true);

      uploadService.upload(ev.target.files[0])
      .then ((fileName) => {
         var url = process.env.REACT_APP_API_URL + '/' + fileName;
         setDriver(driver => ({ ...driver, drivingLicenseUrl: url}));
      })
      .catch((err) => console.error(err));

   }


      
   function uploadPcvLicense(ev) {
      ev.preventDefault();
      ev.stopPropagation();
      
      // Only allow one file
      if (!ev.target.files || ev.target.files.length != 1) {
         return;
      }
      
      var fileType = ev.target.files[0].type;
      if (fileType != 'image/jpg' && fileType != 'image/jpeg' && fileType != 'image/png' && fileType != 'image/gif' && fileType != 'application/pdf') {
         return;
      }

      setChanges(true);

      uploadService.upload(ev.target.files[0])
      .then ((fileName) => {
         var url = process.env.REACT_APP_API_URL + '/' + fileName;
         setDriver(driver => ({ ...driver, pcvLicenseUrl: url}));

      })
      .catch((err) => console.error(err));

   }

      
   function uploadEmploymentContract(ev) {
      ev.preventDefault();
      ev.stopPropagation();
      
      // Only allow one file
      if (!ev.target.files || ev.target.files.length != 1) {
         return;
      }
      
      var fileType = ev.target.files[0].type;
      if (fileType != 'image/jpg' && fileType != 'image/jpeg' && fileType != 'image/png' && fileType != 'image/gif' && fileType != 'application/pdf') {
         return;
      }

      setChanges(true);

      uploadService.upload(ev.target.files[0])
      .then ((fileName) => {
         var url = process.env.REACT_APP_API_URL + '/' + fileName;
         setDriver(driver => ({ ...driver, employmentContractUrl: url}));
      })
      .catch((err) => console.error(err));

   }



   const getProfileImage = function(imageUrl) {
      if (imageUrl !== null && imageUrl !== 'undefined' && imageUrl !== '') {
         return (
            <div className="profile-image" {...getRootProps()} title="Klikk for å laste opp nytt bilde">
               <input  {...getInputProps()} />
               <img src={imageUrl} />
            </div>
         )
      }
      else {
         return ( 
            <div className="upload-dialog-border" {...getRootProps()}>
               <input {...getInputProps()} />
               <div className="frame-header">Dra & slipp</div>
               <div className="frame-text">eller klikk for å laste opp</div>
            </div>);
      }
   }

   const openDocument= function(e, url) {
      e.stopPropagation();
      window.open(url, '_blank');
   }

   const getFileIconHtml = function(url) {
      var fileType = url.split('.').pop().toLowerCase();
      if (fileType === 'pdf') {
         return (<FaRegFilePdf className="document-icon document-icon-pdf" onClick={(e) =>openDocument(e, url)}></FaRegFilePdf>);
      }
      else if (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png' || fileType === 'gif') {
         return (<FaRegFileImage className="document-icon document-icon-image" onClick={(e) =>openDocument(e, url)}></FaRegFileImage>);
      }
      else {
         return (<FaRegFile className="document-icon" onClick={(e) =>openDocument(e, url)}></FaRegFile>);
      }
   }


   function getDocument(ref, documentUrl, drop_func, delete_func, upload_func) {
      if (documentUrl !== null && documentUrl !== 'undefined' && documentUrl !== '') {
         
         return (
            <div className="document-upload-container" onDrop={(e)=>drop_func(e)} >
               <div className="document-icon-container"  title="Klikk for se på dokumentet">
                  {getFileIconHtml(documentUrl)}
               </div>
               <div className="document-delete" title="Slett dokument"><FaPlus className="document-delete-icon" onClick={(e) =>delete_func(e)}></FaPlus></div>
            </div>
         )
      }
      else {
         return ( 
            <div className="document-upload-container">
               <input ref={ref} style={{display:"none"}} type="file" onChange={(e)=>upload_func(e)} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}/>
               <div className="upload-dialog-border"  onDrop={(e)=>drop_func(e)} onClick={() => ref.current.click()}>
                  <div className="frame-header">Dra & slipp</div>
                  <div className="frame-text">eller klikk for å laste opp</div>
               </div>
            </div>);
      }
   }

   const getMenuText = function() {
      if (id == 0){
         return "LEGG TIL";
      }
      else {
         return "ENDRE";
      }
   }


   const getHeading = function() {
      if (id == 0){
         return "Ny sjåfør";
      }
      else {
         return "Endre Sjåfør nr: " + driver.driverNumber;
      }
   }

   const getHomeLocationText = (location) => {
      var link = "";
      if (location.Latitude != 0 && location.longitude != 0) {
         var url = "http://maps.google.com/maps?q=" + location.latitude + "," + location.longitude; //"24.197611,120.780512";
         link = <a href={url} target="_blank"><img className="google-icon" src="./../icons/google-map.png" alt="home" /></a>
      }

      return <><span onClick={() => handleViewHomeLocation(location.homeLocationId)}>{location.name}  </span> {link}</>
   }

   const handleAddHomeLocation = () => {
      history.push('/homeLocation/0/' + id);
   }

   const handleViewHomeLocation =(homeLocationId) => {
      history.push('/homeLocation/' + homeLocationId + '/' + id);
   }

   const closeError = () => {
      setErrors(null);
   }

   let extraPropsHtml = [];


   (Array.isArray(extraProperties)? extraProperties: []).forEach(x=>{

      //Todo: create enum in js for propertyTypes. 
      if (x.propertyType ===  PROPERTY_TYPE.b.name){
         extraPropsHtml.push(<div key={x.appPropertyId} style={{display: 'flex', alignItems: 'center', marginRight: '10px'}}>
         <div style={{marginRight:'2px'}}><DbIcon icon={x.icon}/></div>
            
         <input type="checkbox" checked={x.value == "1"? true: false} 
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} 
            onChange={(e)=>{handleExtraPropertiesChange(x.appPropertyId, e)}}/> 
         <label style={{margin: '0px', marginLeft: '5px'}} className="font-bold block">{x.propertyName} </label>
      </div>);
      }
   })


   return (
      <>
         <div className="calc-container w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4 top-adjustment custom-border-main">
            <div className="flex w-full trip_header ml-2">
               <div className="col-6 text-left">
                  <img src={trip_home_icon} alt="home" />
                  <span className="font-bold"> <Link to='/' className="non-undeline-link">HJEM &nbsp; &nbsp; / &nbsp; </Link></span>
                  <span className="font-bold uppercase"> <Link to='/drivers' className="non-undeline-link">SJÅFØRER &nbsp; &nbsp; / &nbsp;</Link> </span>
                  <span className="font-bold"> {getMenuText()} &nbsp; &nbsp; / &nbsp; </span>
               </div>
               <div className="text-right inline-flex" style={{position:"absolute",right:"30px"}}>
                  <button type="button" className="add_btn" id="backBtn" onClick={()=>handleBack()}> Tilbake </button>
               </div>
            </div>
            
            <div className="form-container">
               <form name="form" className="form flex flex-wrap w-full" onSubmit={handleSubmit} >
                  <h4 className="trip_title font-bold mb-3"> {getHeading()} </h4>

                  <>
                        {errors ? <div className="w-full mb-4">
                           <Alert
                           color="bg-transparent border-red-500 text-red-500 alert-error"
                           onClose={closeError}
                           borderLeft
                           raised>
                           {errors}
                           </Alert>
                        </div>: null}
                     </>

                  <div className="page-content w-full trips drivers">
                     <div className="flex-2 form-container-column">
                        <label className="font-bold block"> Profilbilde (Passfoto) </label>
                        <div className="profile-image-container">
                           {getProfileImage(driver.passportUrl)}
                        </div>
                        <br></br>
                        <div className="display-flex"> 
                           <div className="flex-1">
                              <input type="checkbox"checked={driver.showPhotoInApp} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} onChange={handleDisplayPictureChange}/> 
                           </div>
                           <div className='flex-4'>
                              Vis bilde i app
                           </div>
                        </div>


                     </div>

                     <div className="flex-4 form-container-column">
                        <label className="font-bold block"> Fornavn </label>
                        <input type="text" style={{border:"1px solid #37708e"}} name="firstName" value={driver.firstName} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} onChange={handleChange} className={'form-control' + (submitted && !driver.firstName ? ' is-invalid' : '')} />
                        <label className="font-bold block"> Etternavn </label>
                        <input type="text" style={{border:"1px solid #37708e"}} name="lastName" value={driver.lastName} onChange={handleChange} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} className={'form-control' + (submitted && !driver.lastName ? ' is-invalid' : '')} />
                        <label className="font-bold block"> Epost </label>
                        <input type="text" style={{border:"1px solid #37708e"}} name="email" value={driver.email} onChange={handleChange} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} className={'form-control' + (submitted && !driver.email ? ' is-invalid' : '')} />
                        <label className="font-bold block"> Telefonnummer </label>
                        <Input className="form-control" style={{border:"1px solid #37708e"}}
                           name="phoneNumber"
                           value={phoneValue}
                           onChange={setPhoneValue} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}/>
                     </div>

                     <div className="flex-4 form-container-column">
                        <label className="font-bold block"> Visningsnavn i app </label>
                        <input type="text" style={{border:"1px solid #37708e"}} name="displayName"  value={driver.displayName} onChange={handleChange} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} className={'form-control' + (submitted && !driver.displayName ? ' is-invalid' : '')} />
                        <label className="font-bold block"> Førerkortnummer </label>
                        <input type="number" style={{border:"1px solid #37708e"}} name="drivingLicenseNumber"  value={driver.drivingLicenseNumber} onChange={handleChange} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} className={'form-control' + (submitted && !driver.drivingLicenseNumber ? ' is-invalid' : '')} />
                        <label className="font-bold block"> Kontonummer </label>
                        <input type="number" style={{border:"1px solid #37708e"}} name="accountNumber"  value={driver.accountNumber} onChange={handleChange} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} className={'form-control' + (submitted && !driver.accountNumber ? ' is-invalid' : '')} />
                        <label className="font-bold block"> Rating </label>
                        <input type="number" style={{border:"1px solid #37708e"}} name="rating"  value={driver.rating} onChange={handleChange} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} className={'form-control' + (submitted && !driver.accountNumber ? ' is-invalid' : '')} />
                     </div>

                     <div className="flex-4 form-container-column">
                        <label className="font-bold block"> Passord webportal</label>
                        <input type="password" style={{border:"1px solid #37708e"}} name="passwordWeb" value={driver.passwordWeb} onChange={handleChange} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} className={'form-control' + (submitted && !driver.password ? ' is-invalid' : '')} />
                        <label className="font-bold block"> Passord bilapp</label>
                        <input type="password" style={{border:"1px solid #37708e"}} name="passwordApp" value={driver.passwordApp} onChange={handleChange} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} className={'form-control' + (submitted && !driver.password ? ' is-invalid' : '')} />

                        <label className="font-bold block"> Hjemmeadresser </label>
                        <div className="flex">
                        <div className="license_checkgroup" style={{overflowY:"scroll"}}>
                        {
                              driver.homeLocations.map(item => (
                                 <div key={item.homeLocationId}>
                                    <div className="flex" style={{marginLeft:"10px"}}>
                                       <div className="permit-item" name={item.homeLocationId}>
                                          {getHomeLocationText(item)}
                                       </div>
                                    </div>
                                 </div>
                              ))
                        }
                           
                        </div>
                        <div className="inline_btns">
                              <button type="button" className="inline_btn" onClick={handleAddHomeLocation}> LEGG TIL </button>
                        </div>
                     </div>

                     </div>
                  </div>
                                
                  <div className="w-full">
                     <div className="document-row">
                        <div className="document-container trips">
                           <label className="font-bold block">Førerkort </label>
                           {getDocument(uploadDrivingLicenseRef, driver.drivingLicenseUrl, dropHandlerDrivingLicense, nullstillDriving, uploadDrivingLicense)}
                        </div>
                        <div className="document-container trips">
                           <label className="font-bold block">Kjøreseddel </label>
                           {getDocument(uploadPcvLicenseRef, driver.pcvLicenseUrl, dropHandlerPcvLicense, nullstillPcv, uploadPcvLicense)}
                        </div>
                        <div className="document-container trips">
                           <label className="font-bold block">Ansettelseskontrakt </label>
                           {getDocument(uploadEmploymentContractRef, driver.employmentContractUrl, dropHandlerEmploymentContract, nullstillEmpContract, uploadEmploymentContract)}
                        </div>
                     </div>
                  </div>
                  <div className="w-full">
                     <div className="document-row boolean-extra-types">
                        <div style={{display: 'inline-flex', alignContent: 'center', width: 'auto', flexWrap: 'wrap'}}>
                           {extraPropsHtml ? extraPropsHtml: null}
                        </div>
                     </div>
                  </div>
                  <div className="driver_btns text-right mt-10" style={{float: "right",marginRight: "0px",height: "45px",margin: "30px auto"}}>
                     <button className="sign_btn"  style={{display:"inline", marginLeft:"30px"}} onClick={(e)=>handleBack(e)}> Avbryt </button>                            
                     <button className="sign_btn"  style={{display:"inline", marginLeft:"30px"}} onClick={(e)=>handleSubmit(e)}> Lagre </button>                            
                  </div>
               </form>
            </div>
         </div>
      </>
   )    
}
export default DriverEdit


