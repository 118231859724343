import React, { useState, useEffect, useCallback} from 'react'
import { useParams, Link } from 'react-router-dom'
import trip_home_icon from '../../../assets/image/web-icons/home.png'
import './../../../css/main.css'
import { history,authHeader } from '../../../functions/_helpers'
import axios from 'axios';
import moment from 'moment'
import Input from 'react-phone-number-input/input'
import _ from "lodash";
import {useDropzone} from 'react-dropzone'
import {uploadService} from '../../../functions/services'
import {permitHolderService} from '../../../functions/services'
import { FaRegFile, FaRegFilePdf, FaRegFileImage, FaPlus  } from 'react-icons/fa';
import jwt from 'jwt-decode'
import {Alert} from '../../alerts'

const PermitHolderEdit= ({props}) => {
   const { id }= useParams();

   const [submitted, setSubmitted] = useState(false);
   const [changes, setChanges] = useState(false);
   const [phoneValue, setPhoneValue] = useState();
   const [errors, setErrors] = useState(null);

   const [permitHolder, setPermitHolder] = useState({
      userId:0,
      permitHolderId: 0,
      vatNumber: '',
      accountNumber: '',
      socialSecurityNumber: '',
      companyCertificateUrl: '',
      profileImageUrl: '',
      company:'',
      deleted: false,
      firstName: '',
      lastName:'',
      email: '',
      phoneNumber: '',
      passwordWeb: '',
      permits:[],
   });

   const companyCertificateRef = React.createRef();

   /* Check access rights. Only admin (1) is allowed */
   let user_data = JSON.parse(localStorage.getItem('user'))==null ?  [] : JSON.parse(localStorage.getItem('user'));
   if(user_data === null || user_data.length === 0)
   {
      localStorage.clear();
      history.push('/login');
   }

   // Check Role
   const decodedToken = jwt(user_data.token);
   if (decodedToken == null || decodedToken == undefined) {
         localStorage.clear();
         history.push('/login');  
   }

   const role = parseInt(decodedToken.role);
   if(role !== 1){
      history.push('/');
   }


   useEffect(() => {
      // Load permitHolder
      if (id > 0) {
         permitHolderService.getPermitHolder(id)
         .then(
            (response) => {
               setPermitHolder(response);
               setPhoneValue(response.phoneNumber);
            },
            (error) => {
               console.log(error);
            }
         )
      }
   }, []);


   /* Callback for profile image */
   const onDrop = useCallback(acceptedFiles => {
      // Only allow one file
      if (acceptedFiles.length != 1) {
          return;
      }

      var fileType = acceptedFiles[0].name.split('.').pop().toLowerCase();

      // allow jpg, png and gif
      if (fileType != 'jpg' && fileType != 'jpeg' && fileType != 'png' && fileType != 'gif') {
         return;
      }

      setChanges(true);

      uploadService.upload(acceptedFiles[0])
      .then ((fileName) => {
         var url = process.env.REACT_APP_API_URL + '/' + fileName;

         setPermitHolder(permitHolder => ({ ...permitHolder, profileImageUrl: url}));
      })
      .catch((err) => console.error(err));

   }, []);

   const {getRootProps, getInputProps } = useDropzone({onDrop});


   function handleChange(e) {
      const { name, value } = e.target;
      setPermitHolder(permitHolder => ({ ...permitHolder, [name]: value }));
      setChanges(true);
   }

   function handleError(error){
      try {
         error = JSON.parse(error);
      }
      catch(ex){
         console.log(ex);
      }
      const errorText = error && error.message? error.message: `Det oppstod en feil. ${typeof error === 'string'? error: ''}`;
      setErrors(errorText);
   }


   function handleSubmit(e) {
      e.preventDefault();
      setSubmitted(true);
      setErrors(null);

      if(changes|| (permitHolder.phoneNumber !== phoneValue)) {
         permitHolder.phoneNumber = phoneValue

         if (id > 0) {
            permitHolderService.putPermitHolder(permitHolder)
            .then(
            (response) => {
               history.push('/permitHolders');
            },
            (error) => {
               console.log(error);
               handleError(error);
            })
         }
         else {
            permitHolderService.postPermitHolder(permitHolder)
            .then(
            (response) => {
               history.push('/permitHolders');
            },
            (error) => {
               console.log(error);
               handleError(error);
            })
         }
      }
   }


   const handleBack = () => {
      history.push('/permitHolders');
   }

   const closeError = () => {
      setErrors(null);
   }

   function dropHandlerCompanyCertificate(ev) {
      ev.preventDefault();
      // Only allow one file
      if (!ev.dataTransfer.items || ev.dataTransfer.items.length != 1) {
         return;
      }

      var fileType = ev.dataTransfer.items[0].type;
      if (fileType != 'image/jpg' && fileType != 'image/jpeg' && fileType != 'image/png' && fileType != 'image/gif' && fileType != 'application/pdf') {
         return;
      }
    
      setChanges(true);
      if (ev.dataTransfer.items[0].kind === 'file') {
         var file = ev.dataTransfer.items[0].getAsFile();

         uploadService.upload(file)
         .then ((fileName) => {
            var url = process.env.REACT_APP_API_URL + '/' + fileName;
            setPermitHolder(permitHolder => ({ ...permitHolder, companyCertificateUrl: url}));
         })
         .catch((err) => console.error(err));
      }
   }

   const resetCompanyCertificate = (e) =>{
      e.stopPropagation();
      setChanges(true);
      permitHolder.companyCertificateUrl=null;  
      setPermitHolder(permitHolder => ({ ...permitHolder, companyCertificateUrl: null}));
   }


   function uploadCompanyCertificate(ev) {
      ev.preventDefault();
      ev.stopPropagation();

      // Only allow one file
      if (!ev.target.files || ev.target.files.length != 1) {
         return;
      }
      
      var fileType = ev.target.files[0].type;
      if (fileType != 'image/jpg' && fileType != 'image/jpeg' && fileType != 'image/png' && fileType != 'image/gif' && fileType != 'application/pdf') {
         return;
      }

      setChanges(true);

      uploadService.upload(ev.target.files[0])
      .then ((fileName) => {
         var url = process.env.REACT_APP_API_URL + '/' + fileName;
         setPermitHolder(permitHolder => ({ ...permitHolder, companyCertificateUrl: url}));
      })
      .catch((err) => console.error(err));

   }

   const openDocument= function(e, url) {
      e.stopPropagation();
      window.open(url, '_blank');
   }

   const getFileIconHtml = function(url) {
      var fileType = url.split('.').pop().toLowerCase();
      if (fileType === 'pdf') {
         return (<FaRegFilePdf className="document-icon document-icon-pdf" onClick={(e) =>openDocument(e, url)}></FaRegFilePdf>);
      }
      else if (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png' || fileType === 'gif') {
         return (<FaRegFileImage className="document-icon document-icon-image" onClick={(e) =>openDocument(e, url)}></FaRegFileImage>);
      }
      else {
         return (<FaRegFile className="document-icon" onClick={(e) =>openDocument(e, url)}></FaRegFile>);
      }
   }


   function getDocument(ref, documentUrl, drop_func, delete_func, upload_func) {
      
      if (documentUrl !== null && documentUrl !== 'undefined' && documentUrl !== '') {
         
         return (
            <div className="document-upload-container" onDrop={(e)=>drop_func(e)} >
               <div className="document-icon-container"  title="Klikk for se på dokumentet">
                  {getFileIconHtml(documentUrl)}
               </div>
               <div className="document-delete" title="Slett dokument"><FaPlus className="document-delete-icon" onClick={(e) =>delete_func(e)}></FaPlus></div>
            </div>
         )
      }
      else {
         return ( 
            <div className="document-upload-container">
               <input ref={ref} style={{display:"none"}} type="file" onChange={(e)=>upload_func(e)} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}/>
               <div className="upload-dialog-border"  onDrop={(e)=>drop_func(e)} onClick={() => ref.current.click()}>
                  <div className="frame-header">Dra & slipp</div>
                  <div className="frame-text">eller klikk for å laste opp</div>
               </div>
            </div>);
      }
   }


   const getProfileImage = function(imageUrl) {
      if (imageUrl !== null && imageUrl !== 'undefined' && imageUrl !== '') {
         return (
            <div className="profile-image" {...getRootProps()} title="Klikk for å laste opp nytt bilde">
               <input  {...getInputProps()} />
               <img src={imageUrl} />
            </div>
         )
      }
      else {
         return ( 
            <div className="upload-dialog-border" {...getRootProps()}>
               <input {...getInputProps()} />
               <div className="frame-header">Dra & slipp</div>
               <div className="frame-text">eller klikk for å laste opp</div>
            </div>);
      }
   }


   const getMenuText = function() {
      if (id == 0){
         return "LEGG TIL";
      }
      else {
         return "ENDRE";
      }
   }


   const getHeading = function() {
      if (id == 0){
         return "Ny løyvehaver";
      }
      else {
         return "Endre løyvehaver";
      }
   }

   const handleAddPermit = () => {
      history.push('/permit/0/' + id);
   }
   
   const handleViewPermit = (permitId) => {
      history.push('/permit/' + permitId + '/' + id);
   }

   const getPermitNumber = (permit) => {
      if (permit.shortNumber != null && permit.shortNumber != ''){
         return permit.shortNumber;      
      }
      return permit.permitNumber;
   }

   return (
   <>
      <div className="calc-container w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4 top-adjustment custom-border-main">
         <div className="flex w-full trip_header ml-2">
            <div className="col-6 text-left">
               <img src={trip_home_icon} alt="home" />
               <span className="font-bold"> <Link to='/' className="non-undeline-link">HJEM &nbsp; &nbsp; / &nbsp; </Link></span>
               <span className="font-bold uppercase"> <Link to='/permitholders' className="non-undeline-link">Løyvehavere &nbsp; &nbsp; / &nbsp;</Link> </span>
               <span className="font-bold"> {getMenuText()} &nbsp; &nbsp; / &nbsp; </span>
            </div>
            <div className="text-right inline-flex" style={{position:"absolute",right:"30px"}}>
               <button type="button" className="add_btn" id="backBtn" onClick={()=>handleBack()}> Tilbake </button>
            </div>
         </div>
         
         <div className="form-container">
            <form name="form" className="form flex flex-wrap w-full" onSubmit={handleSubmit} >
               <h4 className="trip_title font-bold mb-3"> {getHeading()} </h4>

               <>
                        {errors ? <div className="w-full mb-4">
                           <Alert
                           color="bg-transparent border-red-500 text-red-500 alert-error"
                           onClose={closeError}
                           borderLeft
                           raised>
                           {errors}
                           </Alert>
                        </div>: null}
                     </>


               <div className="page-content w-full trips drivers">
                  <div className="flex-2 form-container-column">
                     <label className="font-bold block"> Profilbilde (Passfoto) </label>
                     <div className="profile-image-container">
                        {getProfileImage(permitHolder.profileImageUrl)}
                     </div>
                  </div>


                  <div className="flex-4 form-container-column">
                     <label className="font-bold block"> Fornavn </label>
                     <input type="text" style={{border:"1px solid #37708e"}} name="firstName" value={permitHolder.firstName} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} onChange={handleChange} className={'form-control' + (submitted && !permitHolder.firstName ? ' is-invalid' : '')} />
                     <label className="font-bold block"> Etternavn </label>
                     <input type="text" style={{border:"1px solid #37708e"}} name="lastName" value={permitHolder.lastName} onChange={handleChange} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} className={'form-control' + (submitted && !permitHolder.lastName ? ' is-invalid' : '')} />
                     <label className="font-bold block"> Epost </label>
                     <input type="text" style={{border:"1px solid #37708e"}} name="email" value={permitHolder.email} onChange={handleChange} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} className={'form-control' + (submitted && !permitHolder.email ? ' is-invalid' : '')} />
                     <label className="font-bold block"> Telefonnummer </label>
                     <Input className="form-control" style={{border:"1px solid #37708e"}}
                        name="phoneNumber"
                        value={phoneValue}
                        onChange={setPhoneValue} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}/>
                  </div>

                  <div className="flex-4 form-container-column">
                     <label className="font-bold block"> Personnummer </label>
                     <input type="number" style={{border:"1px solid #37708e"}} name="socialSecurityNumber" value={permitHolder.socialSecurityNumber} onChange={handleChange} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} className={'form-control' + (submitted && permitHolder.socialSecurityNumber ? ' is-valid' : '')} />
                     <label className="font-bold block"> Foretaksnummer </label>
                     <input type="number" style={{border:"1px solid #37708e"}} name="vatNumber" value={permitHolder.vatNumber} onChange={handleChange} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} className={'form-control' + (submitted && permitHolder.vatNumber ? ' is-valid' : '')} />
                     <label className="font-bold block"> Foretaksnavn </label>
                     <input type="text" style={{border:"1px solid #37708e"}} name="company"  value={permitHolder.company} onChange={handleChange} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} className={'form-control' + (submitted && !permitHolder.company ? ' is-valid' : '')} />
                     <label className="font-bold block"> Kontonummer </label>
                     <input type="number" style={{border:"1px solid #37708e"}} name="accountNumber"  value={permitHolder.accountNumber} onChange={handleChange} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} className={'form-control' + (submitted && permitHolder.accountNumber ? ' is-valid' : '')} />
                  </div>
                  <div className="flex-4 form-container-column">
                     <label className="font-bold block"> Passord webportal</label>
                     <input type="password" style={{border:"1px solid #37708e"}} name="passwordWeb" value={permitHolder.passwordWeb} onChange={handleChange} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} className={'form-control' + (submitted && !permitHolder.password ? ' is-invalid' : '')} />
                  
                     <label className="font-bold block"> Løyver </label>
                     <div className="flex">
                        <div className="license_checkgroup" style={{overflowY:"scroll"}}>
                        {
                              permitHolder.permits.map(item => (
                                 <div key={item.permitId}>
                                    <div className="flex" style={{marginLeft:"10px"}}>
                                       <div className="permit-item" name={item.permitId}  onClick={() => handleViewPermit(item.permitId)}>
                                          {getPermitNumber(item)} - {item.licenseNumber}
                                       </div>
                                    </div>
                                 </div>
                              ))
                        }
                           
                        </div>
                        <div className="inline_btns">
                              <button type="button" className="inline_btn" onClick={handleAddPermit}> LEGG TIL </button>
                        </div>
                     </div>


                  
                  </div>
               </div> 
               <div className="w-full">
                  <div className="document-row">
                     <div className="document-container trips">
                        <label className="font-bold block">Firmaattest</label>
                        {getDocument(companyCertificateRef, permitHolder.companyCertificateUrl, dropHandlerCompanyCertificate, resetCompanyCertificate, uploadCompanyCertificate)}
                     </div>
                  </div> 
               </div> 
               <div className="driver_btns text-right mt-10" style={{float: "right",marginRight: "0px",height: "45px",margin: "30px auto"}}>
                  <button className="sign_btn"  style={{display:"inline", marginLeft:"30px"}} onClick={(e)=>handleBack(e)}> Avbryt </button>                            
                  <button className="sign_btn"  style={{display:"inline", marginLeft:"30px"}} onClick={(e)=>handleSubmit(e)}> Lagre </button>                            
               </div>
            </form>
         </div>
      </div>
   </>
   )
}
export default PermitHolderEdit
